import styles from './IconBenefits.module.scss';

const IconAdvice = ({ color }) => {
  return (
    <svg
      className={styles.icon}
      width='86'
      height='86'
      viewBox='0 0 86 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M62.8655 4.80908H70.1228C72.1276 4.80908 73.7514 6.43471 73.7514 8.43771V33.8381C73.7514 35.8429 72.1276 37.4667 70.1228 37.4667H55.6083L46.2718 46.8141C46.0124 47.0735 45.6223 47.1515 45.283 47.0136C44.9455 46.8721 44.7224 46.5419 44.7224 46.1754V37.4667H19.322C17.3172 37.4667 15.6934 35.8429 15.6934 33.8381V8.43771C15.6934 6.43471 17.3172 4.80908 19.322 4.80908H26.5792'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0715 68.31C22.5816 68.31 27.0502 63.8413 27.0502 58.3313C27.0502 52.8212 22.5816 48.3525 17.0715 48.3525C11.5614 48.3525 7.09277 52.8212 7.09277 58.3313C7.09277 63.8413 11.5614 68.31 17.0715 68.31Z'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32.9609 84.6387C31.2808 77.2109 24.6822 71.9385 17.0711 71.9385C9.45645 71.9385 2.85779 77.2109 1.17773 84.6387'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M68.7434 68.31C74.2534 68.31 78.7221 63.8413 78.7221 58.3313C78.7221 52.8212 74.2534 48.3525 68.7434 48.3525C63.2333 48.3525 58.7646 52.8212 58.7646 58.3313C58.7646 63.8413 63.2333 68.31 68.7434 68.31Z'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M84.6367 84.6387C82.9566 77.2109 76.3579 71.9385 68.7433 71.9385C61.1322 71.9385 54.5336 77.2109 52.8535 84.6387'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.8359 8.91602V22.9515C33.8359 24.9582 35.4597 26.5802 37.4646 26.5802H51.9791C53.9839 26.5802 55.6077 24.9582 55.6077 22.9515V8.91602'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.207 12.0927L41.1346 2.52769C43.1866 0.729703 46.2564 0.729703 48.3084 2.52769L59.236 12.0927'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41.0938 26.5807V19.3235C41.0938 17.3204 42.7176 15.6948 44.7224 15.6948C46.7272 15.6948 48.351 17.3204 48.351 19.3235V26.5807'
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconHelpToBuy = ({ color }) => {
  return (
    <svg
      width='85'
      height='83'
      viewBox='0 0 85 83'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={styles.icon}
    >
      <path
        d='M12.6994 81.567L5.634 72.7489C3.38823 69.9434 2.17061 66.4625 2.17237 62.8693V44.7225C2.17237 41.8153 4.53041 39.459 7.43587 39.459C10.3431 39.459 12.6994 41.8153 12.6994 44.7225V57.8812'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.246 81.088V70.5609C30.246 67.4397 29.3197 64.3869 27.588 61.7884C20.963 54.8845 18.8962 51.5509 14.8871 54.2213C12.8274 55.5951 12.1098 58.29 13.2169 60.5059L17.961 68.4064'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.3525 81.567L79.4179 72.7489C81.6602 69.9469 82.8795 66.4713 82.8795 62.8851V44.7225C82.8795 41.8153 80.5215 39.459 77.616 39.459C74.7088 39.459 72.3525 41.8153 72.3525 44.7225V57.8812'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8057 81.088V70.5609C54.8057 67.4397 55.7285 64.3869 57.4637 61.7884C64.087 54.8845 66.1555 51.5509 70.1646 54.2213C72.2243 55.5951 72.9419 58.29 71.8296 60.5059L67.0907 68.4064'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.2266 28.9312V48.2307C23.2266 49.1991 24.0108 49.9852 24.9828 49.9852H60.0711C61.0413 49.9852 61.8273 49.1991 61.8273 48.2307V28.9312'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6992 28.9338L40.1238 3.12686C41.4748 1.85485 43.5767 1.85485 44.9276 3.12686L72.3522 28.9338'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.2622 49.9859V37.7044C37.2622 34.7955 39.6203 32.4409 42.5257 32.4409C45.4329 32.4409 47.7892 34.7955 47.7892 37.7044V49.9859'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconPremiumHome = ({ color }) => {
  return (
    <svg
      className={styles.icon}
      width='96'
      height='92'
      viewBox='0 0 96 92'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M89.8364 25.6972H94.0611'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M84.562 10.8662L87.7275 7.70068'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M69.7816 5.6407V1.41602'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M55.004 10.8662L51.8354 7.70068'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M55.7627 24.056C58.4638 17.5642 65.2682 13.7766 72.2062 14.9025C79.1472 16.0316 84.4038 21.7798 84.9106 28.7936C85.4174 35.8044 81.041 42.2446 74.3337 44.357'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5225 56.4956V88.3629C10.5225 89.1975 11.2023 89.8803 12.0399 89.8803H60.5996C61.4372 89.8803 62.1171 89.1975 62.1171 88.3629V56.4956'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.41748 54.993L34.2741 24.9953C35.4335 23.9391 37.2059 23.9391 38.3653 24.9953L71.2219 54.993'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.6848 53.0161C23.5254 56.1998 21.2188 61.1651 29.8624 66.54C30.7456 76.6859 35.6713 77.2231 41.3953 73.7086C47.1193 70.1971 52.9464 57.7234 51.5655 49.6655C42.9978 53.1406 35.1554 49.0494 28.6848 53.0161Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0811 82.0647C17.0811 82.0647 30.6595 60.9655 43.2092 57.709'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconPremiumQuality = ({ color }) => {
  return (
    <svg
      className={styles.icon}
      width='91'
      height='90'
      viewBox='0 0 91 90'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='91'
        height='90'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 89.7795H90.5231V0H0V89.7795Z'
          fill='white'
        />
      </mask>
      <path
        d='M28.1816 69.4014V86.3577C28.1816 87.4075 29.0317 88.2595 30.0815 88.2595H60.2534C61.3012 88.2595 62.1551 87.4075 62.1551 86.3577V69.4014'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.8652 67.5154L43.0642 44.8119C44.2624 43.7697 46.0462 43.7697 47.2501 44.8119L73.4434 67.5154'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.4961 88.2598H50.8079V73.1748H39.4961V88.2598Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.1215 16.1651C76.3212 15.7657 76.7301 15.5146 77.177 15.5146C77.622 15.5146 78.0271 15.7657 78.2268 16.1651L81.8877 23.4812H87.8213C88.3272 23.4793 88.776 23.8007 88.9395 24.28C89.0993 24.7573 88.9395 25.2841 88.5345 25.5903L83.6792 29.3178L85.6837 36.6701C85.8187 37.157 85.6285 37.6762 85.212 37.9595C84.7975 38.241 84.244 38.2296 83.8428 37.9253L77.177 32.8989L70.8555 37.6419C70.4409 37.95 69.8722 37.9519 69.4557 37.6476C69.0393 37.3414 68.87 36.7994 69.0393 36.3107L71.5097 29.3178L66.1885 25.6436C65.7625 25.3526 65.5761 24.8182 65.7282 24.3275C65.8804 23.8331 66.3368 23.4983 66.8484 23.4983H72.4872L76.1215 16.1651Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0162 16.1651C12.214 15.7657 12.621 15.5146 13.0679 15.5146C13.5129 15.5146 13.918 15.7657 14.1177 16.1651L17.7786 23.4812H23.716C24.2219 23.4812 24.6707 23.8045 24.8304 24.2819C24.9902 24.7573 24.8304 25.2841 24.4253 25.5903L19.5777 29.3178L21.5822 36.6511C21.7172 37.138 21.5308 37.6552 21.1105 37.9386C20.694 38.2239 20.1406 38.2106 19.7412 37.9082L13.0679 32.8989L6.74634 37.6419C6.33556 37.95 5.76312 37.9519 5.34663 37.6476C4.93014 37.3414 4.76088 36.7994 4.93014 36.3107L7.38534 29.3178L2.06415 25.6436C1.61723 25.3583 1.41754 24.8144 1.57349 24.3123C1.72563 23.8083 2.19727 23.4698 2.72407 23.4812H8.35526L12.0162 16.1651Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.9594 2.60029L50.8334 12.8338H58.3759C59.1537 12.8034 59.8669 13.2655 60.1559 13.9882C60.4507 14.709 60.2605 15.5381 59.6748 16.0573L53.1307 20.6216L56.7632 28.9476C57.0865 29.7312 56.8754 30.6326 56.2402 31.1936C55.605 31.7528 54.6807 31.844 53.9485 31.4199L45.1756 26.4772L36.4331 31.4199C35.6971 31.844 34.7766 31.7528 34.1414 31.1936C33.5024 30.6326 33.2932 29.7312 33.6165 28.9476L37.247 20.6216L30.7011 16.0573C30.1153 15.5419 29.9213 14.7128 30.2104 13.9901C30.5014 13.2674 31.2146 12.8034 31.9943 12.8338H39.5406L43.4031 2.60029C43.7492 1.93656 44.4339 1.52197 45.1756 1.52197C45.9268 1.52197 46.6095 1.93656 46.9594 2.60029Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
// -------------------------------------------------
const IconWallet = ({ color }) => {
  return (
    <svg
      width='83'
      height='76'
      className={styles.icon}
      viewBox='0 0 83 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M58.4257 43.7092C58.904 43.7092 59.2902 44.097 59.2902 44.5737C59.2902 45.0536 58.904 45.4398 58.4257 45.4398C57.9474 45.4398 57.5596 45.0536 57.5596 44.5737C57.5596 44.097 57.9474 43.7092 58.4257 43.7092'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.5444 35.9186V25.5302C70.5266 19.8003 65.8858 15.1595 60.1559 15.1433H11.6797'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M53.2307 8.2168H11.6801C9.76853 8.2168 8.21729 9.76804 8.21729 11.6812C8.21729 13.5928 9.76853 15.1424 11.6801 15.1424'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.5445 53.2308V63.6193C70.5268 69.3492 65.886 73.99 60.1561 74.0061H11.6798C5.94992 73.99 1.30913 69.3492 1.29297 63.6193V11.6802C1.30913 5.95032 5.94992 1.30952 11.6798 1.29175H53.2304C58.9442 1.29175 63.6189 4.40877 63.6189 8.21739V15.7393'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='-1'
        width='83'
        height='77'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 75.2991H82.2173V-0.000732422H0V75.2991Z'
          fill='white'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.7627 53.2312H80.9249V35.9187H49.7627V53.2312Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconPension = ({ color }) => {
  return (
    <svg
      width='78'
      height='81'
      className={styles.icon}
      viewBox='0 0 78 81'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.7655 48.9965C38.7655 45.2286 43.3443 42.1745 48.9945 42.1745C54.6463 42.1745 59.2266 45.2286 59.2266 48.9965C59.7459 44.7847 63.5294 41.7588 67.7521 42.1745C71.9733 41.7588 75.7553 44.7847 76.2761 48.9965V47.2898C76.2761 25.6318 60.4235 8.07568 38.7655 8.07568C17.1074 8.07568 1.25488 25.6318 1.25488 47.2898V48.9965C1.7741 44.7847 5.55763 41.7588 9.7788 42.1745C14.0015 41.7588 17.7851 44.7847 18.3043 48.9965C18.3043 45.2286 22.8847 42.1745 28.5349 42.1745C34.1851 42.1745 38.7655 45.2443 38.7655 48.9965Z'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M38.7653 8.07587V1.25391'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M38.7653 48.9966V74.5715'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M38.7656 74.5711C38.7656 77.3977 41.0574 79.6864 43.8809 79.6864C46.706 79.6864 48.9946 77.3977 48.9946 74.5711V72.866'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const IconBonus = ({ color }) => {
  return (
    <svg
      width='62'
      height='86'
      viewBox='0 0 62 86'
      fill='none'
      className={styles.icon}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M45.6394 65.3892V82.0778C45.6394 84.081 44.4817 84.5631 43.0628 83.146L31.0654 71.145L19.0644 83.146C17.6474 84.5631 16.4878 84.081 16.4878 83.6117V65.3928'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.2479 20.5466C30.6953 19.6518 31.4276 19.6518 31.8786 20.5466L35.308 27.42H41.997C42.9976 27.42 43.1583 27.9075 42.3603 28.512L36.7269 32.6754L39.0478 40.6335C39.329 41.5941 38.867 41.941 38.0252 41.4023L31.0642 36.9594L24.105 41.2635C23.2522 41.7913 22.7883 41.4407 23.0769 40.4747L25.396 32.6955L19.768 28.5083C18.9627 27.9112 19.127 27.4163 20.1332 27.4163H26.8112L30.2479 20.5466Z'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.91309 31.0645C1.91309 47.1633 14.9641 60.2143 31.0647 60.2143C47.1635 60.2143 60.2146 47.1633 60.2146 31.0645C60.2146 14.9657 47.1635 1.91284 31.0647 1.91284C14.9641 1.91284 1.91309 14.9657 1.91309 31.0645Z'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.022 31.0646C11.022 42.1325 19.9954 51.1059 31.0651 51.1059C42.1312 51.1059 51.1046 42.1325 51.1046 31.0646C51.1046 19.9949 42.1312 11.0215 31.0651 11.0215C19.9954 11.0215 11.022 19.9949 11.022 31.0646Z'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const IconSavings = ({ color }) => {
  return (
    <svg
      width='82'
      height='81'
      viewBox='0 0 82 81'
      className={styles.icon}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.40527 35.2445C1.4018 37.1315 9.04875 38.6602 18.4801 38.6619C27.9148 38.6619 35.5635 37.1332 35.567 35.2463V35.2445C35.5688 33.3576 27.9235 31.8271 18.4888 31.8271C9.05571 31.8271 1.40701 33.3558 1.40527 35.2428V35.2445Z'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.5524 35.2446V45.4933C35.5524 47.382 27.9071 48.9072 18.4724 48.9072C9.03584 48.9072 1.39062 47.382 1.39062 45.4933V35.2446'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.5524 45.4927V55.7414C35.5524 57.6301 27.9071 59.1553 18.4724 59.1553C9.03584 59.1553 1.39062 57.6301 1.39062 55.7414V45.4927'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.5524 55.7407V65.9894C35.5524 67.8781 27.9071 69.4033 18.4724 69.4033C9.03584 69.4033 1.39062 67.8781 1.39062 65.9894V55.7407'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.5524 65.9885V76.2372C35.5524 78.1259 27.9071 79.6529 18.4724 79.6529C9.03584 79.6529 1.39062 78.1259 1.39062 76.2372V65.9885'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6484 23.2848C19.238 13.3926 30.7702 6.36131 44.2589 6.36131C47.7267 6.36305 51.1737 6.84478 54.5076 7.79783C56.6363 4.77 59.7093 2.54044 63.245 1.45522C64.1545 1.20131 65.0971 1.73 65.351 2.63957C65.3945 2.79087 65.4137 2.94565 65.4137 3.10391V13.21C69.0363 16.3839 71.7754 20.4413 73.3615 24.9891H76.5615C78.4484 24.9891 79.9771 26.5178 79.9771 28.4048V38.6535C79.9771 40.5404 78.4484 42.0709 76.5615 42.0709H73.365C71.5667 46.5474 68.4658 50.3839 64.4658 53.083L67.2241 60.5683C68.2363 63.3091 66.8328 66.3491 64.0937 67.3596C61.351 68.37 58.311 66.9665 57.3006 64.2257L54.8867 57.6848C51.4397 58.7196 47.8606 59.2483 44.2624 59.2535'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M58.6242 23.2881C58.1529 23.2881 57.7686 23.6707 57.7686 24.142C57.7686 24.6133 58.1529 24.9959 58.6242 24.9959C59.0955 24.9959 59.4764 24.6133 59.4764 24.142C59.4764 23.6707 59.0955 23.2881 58.6242 23.2881'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.0117 19.8728C30.5526 15.9668 35.4274 13.5302 40.6761 13.0398'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const IconHealthInsurance = ({ color }) => {
  return (
    <svg
      width='82'
      height='77'
      viewBox='0 0 82 77'
      className={styles.icon}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.37109 45.7389H20.9974C21.534 45.7389 22.0392 45.4862 22.3612 45.057L25.5213 40.8451C26.086 40.0905 27.1559 39.937 27.9104 40.5017C28.0656 40.6172 28.1994 40.7592 28.305 40.9194L32.162 46.7229C32.687 47.5055 33.7453 47.7168 34.5296 47.1935C34.7756 47.0284 34.977 46.8038 35.1091 46.538L40.3628 36.0256C40.7855 35.1819 41.8092 34.8401 42.6529 35.2628C43.0822 35.4758 43.3992 35.8605 43.5312 36.3195L47.9479 51.7752C48.2038 52.6833 49.1498 53.2083 50.0546 52.9508C50.5169 52.8187 50.9 52.5017 51.1146 52.074L53.8009 46.7014C54.0899 46.1219 54.6793 45.757 55.3265 45.757H79.8681'
        stroke={color}
        strokeWidth='2.27848'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.2058 38.9105C74.8425 33.6882 76.2938 27.9491 76.454 22.101C76.454 -4.13955 44.6592 -6.18688 40.6191 20.0916C36.5789 -6.18688 4.78418 -4.13955 4.78418 24.1186C4.85022 29.2039 5.90526 34.2297 7.8948 38.9105'
        stroke={color}
        strokeWidth='2.27848'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8081 52.561C22.5709 61.2771 30.5704 68.9578 39.5588 75.3558C40.1846 75.7999 41.0282 75.7718 41.6226 75.2881C49.8186 68.5566 57.2385 60.9353 63.7454 52.561'
        stroke={color}
        strokeWidth='2.27848'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const IconStroller = ({ color }) => {
  return (
    <svg
      width='81'
      height='81'
      className={styles.icon}
      viewBox='0 0 81 81'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5996 74.5482C11.5996 77.3656 13.8831 79.6525 16.704 79.6525C19.5231 79.6525 21.8066 77.3656 21.8066 74.5482C21.8066 71.7291 19.5231 69.4438 16.704 69.4438C13.8831 69.4438 11.5996 71.7291 11.5996 74.5482Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.0283 74.5482C49.0283 77.3656 51.3118 79.6525 54.1327 79.6525C56.9501 79.6525 59.2353 77.3656 59.2353 74.5482C59.2353 71.7291 56.9501 69.4438 54.1327 69.4438C51.3118 69.4438 49.0283 71.7291 49.0283 74.5482Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M79.6519 11.5996H73.1319C69.9737 11.5996 67.2293 13.7718 66.5058 16.8483L54.1319 69.4448H16.7041'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M55.7337 62.6376H16.3163C13.4763 62.6376 10.9355 60.8741 9.94242 58.215L1.39111 35.4185H62.1389'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.0142 35.4177V3.09421C32.0159 2.15334 31.2542 1.39334 30.3133 1.3916C30.2907 1.3916 30.2664 1.3916 30.2403 1.39334C15.5429 2.34117 1.3916 14.9046 1.3916 30.2594V35.4177H32.0142Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const IconWellbeing = ({ color }) => {
  return (
    <svg
      width='91'
      height='83'
      viewBox='0 0 91 83'
      className={styles.icon}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M88.7901 37.2959C64.6528 37.2959 45.0864 56.8622 45.0864 80.9996C69.222 80.9996 88.7901 61.4332 88.7901 37.2959Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M56.5861 51.4616C62.7061 33.9566 58.2581 14.4907 45.1406 1.38354C31.9781 14.546 27.556 34.1037 33.7746 51.6503'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.38428 37.2959C25.5164 37.2959 45.0862 56.8622 45.0862 80.9996C20.9506 80.9996 1.38428 61.4332 1.38428 37.2959Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const IconEducation = ({ color }) => {
  return (
    <svg
      width='101'
      height='73'
      className={styles.icon}
      viewBox='0 0 101 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.8489 2.5637L2.63498 19.8435C1.99644 20.0996 1.68742 20.8235 1.94351 21.462C2.06986 21.7761 2.32083 22.0271 2.63498 22.1535L45.8489 39.4435C48.2221 40.391 50.8667 40.391 53.2399 39.4435L99.3408 20.9891L53.2399 2.5637C50.8667 1.61614 48.2221 1.61614 45.8489 2.5637Z'
        stroke={color}
        strokeWidth='2.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.666 45.8867V60.8257C27.8202 68.1331 38.636 71.7389 49.5441 70.7845C60.4521 71.7389 71.268 68.1331 79.4221 60.8257V45.8867'
        stroke={color}
        strokeWidth='2.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M99.341 20.998V70.7937'
        stroke={color}
        strokeWidth='2.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const IconDisabilitySupport = ({ color }) => {
  return (
    <svg
      width='85'
      height='81'
      className={styles.icon}
      viewBox='0 0 85 81'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.1255 79.6375L4.96368 70.6829C2.69277 67.8466 1.45459 64.3193 1.45459 60.6847V42.2866C1.45459 39.3393 3.84368 36.9502 6.78914 36.9502C9.73823 36.9502 12.1255 39.3393 12.1255 42.2866V55.6266'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.1339 79.1514V68.4786C28.1357 65.3132 27.1994 62.2186 25.443 59.5859L21.3757 53.5059C19.9612 51.1477 16.9012 50.3823 14.543 51.7968C14.4739 51.8368 14.4085 51.8786 14.343 51.9223C12.2539 53.3168 11.5285 56.0477 12.6521 58.2932L17.463 66.2986'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.6016 79.6375L79.7652 70.6829C82.0343 67.8466 83.2725 64.3193 83.2725 60.6847V42.2866C83.2725 39.3393 80.8852 36.9502 77.9379 36.9502C74.9888 36.9502 72.6016 39.3393 72.6016 42.2866V55.6266'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M56.5928 79.1514V68.4786C56.591 65.3132 57.5291 62.2186 59.2855 59.5859L63.351 53.5059C64.7655 51.1477 67.8255 50.3823 70.1855 51.7968C70.2528 51.8368 70.32 51.8786 70.3855 51.9223C72.4746 53.3168 73.2019 56.0477 72.0782 58.2932L67.2655 66.2986'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.7967 6.42753C37.0131 -2.62702 19.6367 0.60753 19.6367 16.7712C19.944 29.1148 36.0131 38.9439 41.204 41.7784C42.1949 42.3275 43.4004 42.3275 44.3894 41.7784C49.5822 38.9203 65.6549 29.0966 65.9567 16.7712C65.9567 0.60753 48.5894 -2.62702 42.7967 6.42753Z'
        stroke={color}
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconBarGraph = ({ color = '#F5841F' }) => {
  return (
    <svg width='84' height='86' viewBox='0 0 84 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M22.5799 44.1439C23.4644 44.8154 24.0078 45.8407 24.0674 46.9491V74.886C24.0042 75.9998 23.4644 77.0341 22.5871 77.7237L14.7166 83.215C13.8122 83.7819 12.6605 83.7819 11.7561 83.215L3.88552 77.7237C3.00459 77.0341 2.46665 76.0016 2.40527 74.886V46.9491C2.46665 45.8371 3.01723 44.8118 3.90537 44.1439L11.7326 38.9233C12.6605 38.3745 13.8122 38.3745 14.7364 38.9233L22.5799 44.1439Z'
            stroke={color} strokeWidth='2.88828' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.251 52.3625L2.83691 45.418' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M23.6622 45.4219L13.25 52.3664' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M13.2507 52.3633V83.5386' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M51.4627 24.2845C52.3472 24.956 52.8906 25.9813 52.9502 27.0897V74.8835C52.887 75.9973 52.3472 77.0335 51.4699 77.723L43.6012 83.2144C42.695 83.7794 41.5451 83.7794 40.6389 83.2144L32.7683 77.723C31.8892 77.0335 31.3495 75.9991 31.2881 74.8835V27.0897C31.3531 25.9777 31.9091 24.9488 32.8044 24.2845L40.6317 19.0639C41.5577 18.5152 42.7076 18.5152 43.6355 19.0639L51.4627 24.2845Z'
            stroke={color} strokeWidth='2.88828' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M42.1334 32.5052L31.7212 25.5625' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M52.5469 25.5625L42.1328 32.5052' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M42.1335 32.5078V83.5978' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M80.3473 8.03839C81.2301 8.70992 81.7752 9.73525 81.833 10.8436V74.884C81.7698 75.9977 81.2301 77.0339 80.3545 77.7235L72.484 83.2148C71.5778 83.7799 70.4279 83.7799 69.5235 83.2148L61.6529 77.7235C60.772 77.0339 60.2341 75.9996 60.1709 74.884V10.8436C60.2341 9.73164 60.7847 8.70631 61.6728 8.03839L69.5145 2.81783C70.4423 2.26906 71.5922 2.26906 72.5183 2.81783L80.3473 8.03839Z'
            stroke={color} strokeWidth='2.88828' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M71.0162 16.2591L60.604 9.31641' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M81.4297 9.31641L71.0156 16.2591' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M71.0164 16.2578V83.5962' stroke={color} strokeWidth='2.88828' strokeLinecap='round'
            strokeLinejoin='round' />
    </svg>

  );
};

const IconHomeConstruction = ({ color = '#F5841F' }) => {
  return (
    <svg width='80' height='81' viewBox='0 0 80 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.36133 25.2738L38.2005 2.84831C39.2694 2.1964 40.6175 2.1964 41.6881 2.84831L78.5273 25.2738'
            stroke={color} strokeWidth='2.7234' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M78.5273 33.6641V78.9577' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M58.3975 33.6641V78.9577' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M28.2016 40.375V78.9571' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M58.3975 37.0195L78.5268 53.794' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M78.5273 37.0195L58.3979 53.794' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M58.3975 58.8281L78.5268 75.6026' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M78.5273 58.8281L58.3979 75.6026' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M78.5273 55.4722H58.3979' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M14.7808 55.4765V17.1055' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M51.6854 78.9565H34.9126' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M65.1064 17.1055V30.3072' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M1.36132 48.7617V78.9575' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M1.36133 62.1792H28.2022' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M1.36133 63.8594L28.2022 78.9572' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M28.2017 63.8594L1.36084 78.9572' stroke={color} strokeWidth='2.7234' strokeLinecap='round'
            strokeLinejoin='round' />
      <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='1' width='80' height='80'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0 80.3174H79.8894V1H0V80.3174Z' fill='white' />
      </mask>
      <g mask='url(#mask0)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M34.9121 31.9844H44.9768V21.918H34.9121V31.9844Z'
              stroke={color} strokeWidth='2.7234' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M34.9116 57.1504C38.6172 54.3708 43.8733 55.1231 46.6529 58.8287C47.7423 60.2789 48.3329 62.044 48.3329 63.8601V78.958'
          stroke={color} strokeWidth='2.7234' strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </svg>

  );
};

const IconHousingDevelopment = ({ color = '#F5841F' }) => {
  return (
    <svg width='139' height='78' viewBox='0 0 139 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M62.2144 44.5078V72.5793C62.2144 74.3165 63.629 75.726 65.3725 75.726H115.505C117.245 75.726 118.667 74.3165 118.667 72.5793V44.5078'
        stroke={color} strokeWidth='3.04762' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.4136 41.3862L86.9446 3.80131C88.9343 2.07144 91.8996 2.07144 93.8985 3.80131L137.422 41.3862'
            stroke={color} strokeWidth='3.04762' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.7241 41.3862L87.2551 3.80131C89.2448 2.07144 92.2101 2.07144 94.209 3.80131' stroke={color}
            strokeWidth='3.04762' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M49.2355 44.5078V72.5793' stroke={color} strokeWidth='3.04762' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M29.9844 41.3862L73.5154 3.80131C75.5051 2.07144 78.4704 2.07144 80.4693 3.80131' stroke={color}
            strokeWidth='3.04762' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M35.4958 44.5078V72.5793' stroke={color} strokeWidth='3.04762' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M16.2446 41.3862L59.7757 3.80131C61.7654 2.07144 64.7306 2.07144 66.7295 3.80131' stroke={color}
            strokeWidth='3.04762' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.756 44.5078V72.5793' stroke={color} strokeWidth='3.04762' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M2.50488 41.3862L46.0359 3.80131C48.0256 2.07144 50.9909 2.07144 52.9898 3.80131' stroke={color}
            strokeWidth='3.04762' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M81.0132 75.7281H99.8098V50.7539H81.0132V75.7281Z' stroke={color}
            strokeWidth='3.04762' strokeLinecap='round' strokeLinejoin='round' />
    </svg>

  );
};

const IconStrategicPlan = ({ color = '#F5841F' }) => {
  return (
    <svg width='91' height='80' viewBox='0 0 91 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M2.42634 68.9999C2.32148 70.8124 3.51155 72.4461 5.26419 72.8858L23.8508 77.5526C24.4767 77.7098 25.1325 77.7031 25.7533 77.5342L44.4897 72.4026C45.1372 72.2253 45.8196 72.2253 46.4671 72.4026L65.2035 77.5342C65.826 77.7031 66.4802 77.7098 67.1043 77.5526L85.6993 72.8858C87.4453 72.4477 88.6387 70.8258 88.5405 69.02L85.2033 6.94401C85.0901 4.8606 83.3192 3.26544 81.2453 3.37914C80.979 3.39419 80.7177 3.43599 80.4613 3.50622L65.3167 7.65966C64.6393 7.84861 63.9219 7.83858 63.2495 7.63291L46.562 2.57989C45.8579 2.3692 45.1072 2.3692 44.4049 2.57989L27.719 7.60616C27.0465 7.81182 26.3292 7.82186 25.6501 7.63291L10.5271 3.48783C8.5248 2.93771 6.45758 4.12322 5.91165 6.13473C5.84008 6.3939 5.7968 6.65642 5.78349 6.92395L2.42634 68.9999Z'
            stroke={color} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M58.6416 23.0234L73.6764 38.1273' stroke={color} strokeWidth='3' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M58.6416 38.1273L73.6764 23.0234' stroke={color} strokeWidth='3' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M15.4136 41.9035L21.3622 40.4873' stroke={color} strokeWidth='3' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M29.4375 45.0977L32.3269 49.4568' stroke={color} strokeWidth='3' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M39.8433 57.0079H45.4823' stroke={color} strokeWidth='3' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M51.1206 51.3436L54.8789 47.5664' stroke={color} strokeWidth='3' strokeLinecap='round'
            strokeLinejoin='round' />
    </svg>

  );
};

export {
  IconWallet,
  IconPension,
  IconBonus,
  IconSavings,
  IconHealthInsurance,
  IconStroller,
  IconWellbeing,
  IconEducation,
  IconDisabilitySupport,
  IconAdvice,
  IconHelpToBuy,
  IconPremiumHome,
  IconPremiumQuality,
  IconBarGraph,
  IconHomeConstruction,
  IconHousingDevelopment,
  IconStrategicPlan,

};
