import NextLink from 'next/link';
import React from 'react';

import { SiteContext } from '../../context';
import { getPathForId } from '../../helpers/sitemap/getPathForId';
import { isInternalLink } from '../../helpers/sitemap/isInternalLink';

export const InternalExternalLink = ({
  link,
  id,
  href,
  children,
  className,
  target,
  rel,
  style,
  download,
  params = '',
  ...props
}) => {
  const { sitemap } = React.useContext(SiteContext);

  // new situation: link is an object containing an external or internal link with optional params
  if (!href && !id && link && typeof link === 'object') {
    if (link?.external) href = link?.external;
    if (link?.internal?._ref) href = getPathForId(link.internal._ref, sitemap);
    if (link?.params) href = `${href}${link?.params}`;
  }

  // new situation: passed an id
  if (!href && id) href = `${getPathForId(id, sitemap)}${params}`;

  // old situation: link is a string
  if (typeof link === 'string') href = link;

  // remove special characters from tel
  if (href?.indexOf('tel') === 0) href = href.replace(/\s|\(|\)/g, '');

  if (isInternalLink(href)) {
    return (
      <NextLink href={href} shallow={href?.indexOf('#') === 0} download={download}>
        <a className={className} target={target} rel={rel} style={style} {...props}>
          {children}
        </a>
      </NextLink>
    );
  }

  return (
    <a
      href={href}
      className={className}
      target={target}
      rel={rel}
      download={download}
      {...props}
    >
      {children}
    </a>
  );
};

export const InternalExternalLinkMemo = React.memo(InternalExternalLink);
